<style lang="less" scoped>
  @import "../../assets/css/variables";
  .cust-wraper{
    font-size: 14px;
    color: #666666;
    padding:15px;
    & > div {
      margin-bottom: 30px;
    }
  }
  .logo {
    max-width: 200px;
    vertical-align: top;
  }
  .goCompany {
    color: #5A308F;
    &:hover {
      color: red;
    };
  }
 
  .flex-w {
    width: 100%;
    display: flex;
    .f-lt {
      flex: 0 0 100px;
    }
    .f-rt {
      flex: 1 1 auto;
      overflow: hidden;
      word-wrap: break-word;
    }
  }

</style>

<template :is="view"
          transition="fade"
          transition-mode="out-in">
  <div class="page-wrapper">
    <el-breadcrumb separator="/">
      <el-breadcrumb-item :to="{ path: '/customer/list' }">客户列表</el-breadcrumb-item>
      <el-breadcrumb-item>客户详情</el-breadcrumb-item>
    </el-breadcrumb>

    <div class="page-container">
      <div >
        <el-row>
          <el-col :span="14" class="cust-wraper">
            <el-row>
              <el-col :span="12">
                <span>公司名称：</span>
                <span>{{custInfo.name}}</span>
              </el-col>
              <el-col :span="12" v-if="isBrander">
                <!-- <router-link :to="{path: '/customer/company/'+custInfo.companyId}">
                  公司详情
                </router-link> -->
                <!-- <router-link to="">公司详情</router-link> -->
                <span @click="goCompany" class="goCompany">公司详情</span>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="12">
                <span>联 系 人：</span>
                <span>{{custInfo.contact}}</span>
              </el-col>
              <el-col :span="12">
                <span>联系电话：</span>
                <span>{{custInfo.contactTel}}</span>
              </el-col>
            </el-row>
            <div>
              <span>公司地址：</span>
              <span>{{custInfo.address}}</span>
            </div>
            <div>
              <span>客户类型：</span>
              <span>{{custInfo.isProxy ? '品牌商' : '经销商'}}</span>
            </div>
            <div v-if="custInfo.isProxy">
              <span>公 众 号：</span>
              <span>{{custInfo.pumberNumberName}}</span>
            </div>
            <div class="flex-w" v-if="custInfo.isProxy">
              <span class="f-lt">外链地址：</span>
              <div class="f-rt">{{custInfo.outChain}}</div>
            </div>
<!--             <div>
              <span>滤芯商城：</span>
              <span>{{custInfo.storeAddress}}</span>
            </div> -->
            <div>
              <span>备    注：</span>
              <span>{{custInfo.remarks}}</span>
            </div>
          </el-col>
          <el-col :span="10">
            <span>Logo：</span>
            <img class="logo" :src="API_ROOT+custInfo.logoUrl">
            <img v-if="!custInfo.logoUrl" style="vertical-align:middle;margin:10px 0;" height="200" width="150" src="../../assets/images/nophoto.gif">
          </el-col>


        </el-row>

      </div>
    </div>
  </div>

</template>

<script>

  import {getCustomerById} from '../../services/customer';
  import {Notification} from 'element-ui';
  import {API_ROOT} from '../../config';
  import {PAGE_SIZE} from '../../config';
  import CustomerDetail from './detail.vue';
  import {mapGetters, mapActions} from "vuex";
  import moment from 'moment';
  import ElButton from "../../../node_modules/element-ui/packages/button/src/button";

  export default{
    data(){
      return {
        custInfo: {},
        API_ROOT,
        isBrander: false,
      }
    },
    components: {
    },
    created() {
      const id = this.$route.params.id;
      this.getInfo(id);
      // this.;
    },
    computed: {
      ...mapGetters(
        {user: 'userInfo'}
      )
    },
    methods: {
      /**
       * 获取客户信息；
       */
      async getInfo(id) {
        const res = await getCustomerById(id);
        if (res && res.errorCode === 0 && res.data) {
          this.custInfo = res.data;
          if(this.custInfo.isProxy) {
            this.isBrander = true;
          }
        }  
      },
      goCompany() {
        if(this.custInfo.companyId) {
          window.router.push({path: '/customer/company/'+this.custInfo.companyId})
        } else {
          Notification.warning({
            title: '提示',
            message: '还未新建该公司的介绍页面'
          })
        }
        
      }
    }
    
  }

</script>
